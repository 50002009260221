
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500&family=Newsreader:ital,wght@1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.Scale{
  position: fixed;
}


.firstP{
  position:fixed;
  right: 70%;
  color:#ffffff;
  font-family: 'Syncopate', sans-serif;
  
  
  
 
  line-height: 1.2;
  font-size: 1vw;
  
  
  animation: antidivhover 1s forwards ;

}

.firstP:hover{
  animation: divhover 1s forwards ;
}


.scndP{
  position: fixed;
  left: 70%;
  color:#ffffff;
  color:#ffffff;
  font-family: 'Syncopate', sans-serif;
  
  
 
  line-height: 1.2;
  font-size: 1vw;
  
  
  animation: antidivhover 1s forwards ;


}

.scndP:hover{
  animation: divhover 1s forwards ;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 30s ;
    
  }
  .Scale {

    animation: App-logo-scale infinite 20s ;
  }
}

.App-header {
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}
.bhima{
  position: relative;
  margin-top: 300px;
}

.App-link {
  color: #61dafb;
}
body{
  
 background-color: black;
 overflow: hidden
  
  
}





.HomeTR{
 
 
  animation: Transition  3s ;
}

.Spin{
  
  position: fixed;

  top: 50%;
  left: 60%;
  margin-top: -100px; 
  margin-left: -250px; 
  animation: App-logo-spin infinite 1s ;
  z-index: 1;
}




.Static{
  
  position: fixed;
  
  

  top: 50%;
  left: 60%;
  margin-top: -100px; 
  margin-left: -250px; 
  z-index: 0;
}

.Mist{

  position:fixed;
  
  top: 50%;
  left: 60%;
  margin-top: -100px; 
  margin-left: -250px; 
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}


.bagimg{
  position: relative;
height: 50px;
width: 50px;
top: 30%;
right: 4%;
}


.ligne {
  position: relative;
  left: 40%;
}

.viewport-header {
  position: fixed;
  
  height: 110px;
  width: 100%;
  text-align: center;
  display: flex;
 background-color: black;
  justify-content: center;
  -webkit-box-shadow: 5px 5px 25px 5px rgba(0,0,0,0.71); 
  box-shadow: 5px 5px 25px 5px rgba(0,0,0,0.71);
}

.cpl{
  position: fixed;
 left: 50%;
  height: 100px;
  width: 100px;
  
}

.quote{
  font-family: 'Lora', serif;
  font-size: 25px;
  text-shadow: 1px 1px 2px rgb(201, 200, 200);

  position: relative;
  height:40vh;
  width: 70vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .2); 
  margin-top: 200px;
  margin-left: 1000px;
  margin-right: 500px;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.27); 
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.27);
  border-radius: 25px;
  animation: antidivhover 1s forwards ;
}

.shopLink:link{
  color: #ffffff;
  text-decoration: none;
}
.shopLink:visited{
  color: #ffffff;
  text-decoration: none;
}


.ligneb{
  color: #ffffff;
  width: 100%;
  position: fixed;
      left: 0%;
      margin-top:100px;
}

.quote:hover{
  animation: divhover 1s forwards ;
}

.mainText {
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Syncopate', sans-serif;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: 3vw;
  line-height: 1.2;
  font-size: 3vw;
  text-align: center;
  text-shadow: 12px 12px 15px rgb(0, 0, 0);
  animation: antidivhover 1s forwards ;
  flex-direction: column;
}
.mainTextsh {
  position: relative;
  margin: 0px;
  padding: 0px;
  top: -10%;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Syncopate', sans-serif;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: 3vw;
  line-height: 1.2;
  font-size: 3vw;
  text-align: center;
  text-shadow: 12px 12px 15px rgb(0, 0, 0);
  animation: antidivhover 1s forwards ;
  flex-direction: column;
 
}
.prod {
  border-style: solid;
  border-color: #ffffff;
  border-radius: 5px;
}


.mainTextA {
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Syncopate', sans-serif;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: 3vw;
  line-height: 1.2;
  font-size: 1vw;
  text-align: center;
  text-shadow: 12px 12px 15px rgb(0, 0, 0);
  animation: antidivhover 1s forwards ;
  flex-direction: column;
}
.aback {
  
 
  
}


.textab{
  letter-spacing: 0.3vw;
  
}

.aboutTitle{
  position: fixed;
  left: 27%;
  margin-top: 10%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Syncopate', sans-serif;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: 1vw;
  line-height: 1.2;
  font-size: 3vw;
  text-align: center;
  text-shadow: 12px 12px 15px rgb(255, 253, 253);
  
  flex-direction: column;
  animation: antidivhover 1s forwards ;
}

.aboutTitle:hover{
  animation: divhover 1s forwards ;
}

  span {
    display: block;
    font-size: 10vw;
    letter-spacing: 3vw;
  }

  .mainText:hover{
    animation: divhover 1s forwards ;
  }

  .Copyr{
    color: #ffffff;
  }
  .my3d {
   
    position: fixed;
   top: 20%;
   
    
   
  }

  .delem {
    position: fixed;
    right: 10%;
    height: 50%;
    width: 50%;
    }

  .delemtw {
  position: fixed;
  right: 0%;
  left: 60%;
  top: 10%;
  width: 50%;
  height: 50%;
  }

.test{
  background-color: #ffffff;
  font-size: xx-large;
}

  .button {
	
    --offset: 10px;
    --border-size: 2px;
    
    display: block;
    position: relative;
    padding: 1.5em 3em;
    appearance: none;
    border: 0;
    background: transparent;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: .25em;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 0;
    box-shadow: inset 0 0 0 var(--border-size) currentcolor;
    transition: background .8s ease;
    
    &:hover {
      background: rgba(100, 0, 0, .03);
    }
    
    &__horizontal,
    &__vertical {
      position: absolute;
      top: var(--horizontal-offset, 0);
      right: var(--vertical-offset, 0);
      bottom: var(--horizontal-offset, 0);
      left: var(--vertical-offset, 0);
      transition: transform .8s ease;
      will-change: transform;
      
      &::before {
        content: '';
        position: absolute;
        border: inherit;
      }
    }
    
    &__horizontal {
      --vertical-offset: calc(var(--offset) * -1);
      border-top: var(--border-size) solid currentcolor;
      border-bottom: var(--border-size) solid currentcolor;
      
      &::before {
        top: calc(var(--vertical-offset) - var(--border-size));
        bottom: calc(var(--vertical-offset) - var(--border-size));
        left: calc(var(--vertical-offset) * -1);
        right: calc(var(--vertical-offset) * -1);
      }
    }
    
    &:hover &__horizontal {
      transform: scaleX(0);
    }
    
    &__vertical {
      --horizontal-offset: calc(var(--offset) * -1);
      border-left: var(--border-size) solid currentcolor;
      border-right: var(--border-size) solid currentcolor;
      
      &::before {
        top: calc(var(--horizontal-offset) * -1);
        bottom: calc(var(--horizontal-offset) * -1);
        left: calc(var(--horizontal-offset) - var(--border-size));
        right: calc(var(--horizontal-offset) - var(--border-size));
      }
    }
    
    &:hover &__vertical {
      transform: scaleY(0);
    }
    
  }

  @media only screen and (max-width: 600px) {
    .ligne {
      position: relative;
      left: 20%;

      .Spin{
        position: absolute;
      }
    }
  }

  @media (min-width: 400px)  {

  }

  @media (min-width: 320px) and (max-width: 480px) {
  
   .Spin{
     left:80%;
     top:40%
   }

   .Static{
    left:80%;
    top:40%

   }

   .Mist{
    left:80%;
    top:40%
   }
    
  }

   
  
  


  @media screen and (max-width: 1100px) {
    .bagimg{
      position: relative;
    height: 50px;
    width: 50px;
    top: 30%;
    right: 4%;
    }
  
    .ligne {
      position: relative;
      left: 40%;
    }

    .cpl{
      position: fixed;
     left: 40%;
      height: 100px;
      width: 100px;

 
      
    }

    
    



    
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */

    .scndP {
      margin-top: 100%;
      left: 0%;
      font-size: 50%;
      height: 3%;
      width: 100%;
      
    }

    .firstP {
      margin-top: 30%;
      left: 0%;
      font-size: 50%;
      height: 3%;
      width: 100%;
      
    }

    .aboutTitle{

      margin-top: 30%;
      left: 20%;
      font-size: 120%;
    }

 .delem{
   opacity:0;

   
 }
  

}
 
.my3d{
  display: flex;
  position: fixed;
  
  height: 50%;
  width: 50%;
  margin-top: 15%;
  left: 25%;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-scale {
  from {
    transform:  scale(1);
  }
  to {
    transform:  scale(1.5);
  }

}

@keyframes Transition {
  from {
    opacity:0;
  }
  to {
    opacity: 1;
  }
  
}

@keyframes divhover {
  from {
    transform:scale(1);
  }
  to {
    transform: scale(1.2);
  }
  
}


@keyframes antidivhover {
  from {
    transform:scale(1.2);
  }
  to {
    transform: scale(1);
  }
  
}

